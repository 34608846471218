<template>
  <div v-if="project">
     <h4 v-if="project.name" class="has-text-weight-semibold is-size-6 has-text-danger">{{ project.name }}</h4>
  </div>
</template>

<script>
  import { ref, onMounted } from "vue";
  import api from '@/helpers/api'
  import { env } from '@/configs'

  export default {
    props: {
      id: {
        type: [String, Number],
        required: true
      }
    },

    setup(props){

      const project = ref(null)

       function getProjectByID (id) {
        api.get({ url: env.GITLAB_API_URL + '/projects/' + id })
        .then(res => {
          project.value = res.data
        })
      }

      onMounted(() => {
        getProjectByID(props.id)
      })


      return {
        project
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>